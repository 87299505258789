<template>
  <el-dialog :visible.sync="dialogVisible" width="30%">
    <div class="title">
      <i class="el-icon-question icon"></i>
      您已购买多个版本，请选择一个版本进行次数扣除
    </div>
    <div class="pickers">
      <el-radio
        v-model="versionRadio"
        :label="version.edition"
        v-for="(version) in versions"
        :key="version.id"
      >{{version.name}}</el-radio>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="handleVersionSelect">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    isTryVersion: Boolean,
    versions:Array
  },
  data() {
    return {
      dialogVisible: false,
      versionRadio: 3
    };
  },
  async mounted() {
      this.versionRadio = this.versions[0].edition
  },
  methods: {
    handleVersionSelect() {
      this.$emit("versionSelect", this.versionRadio);
      this.dialogVisible = false;
    },
    open() {
      this.dialogVisible = true;
    }
  }
};
</script>

<style lang="stylus" scoped>
>>> .el-dialog__header {
  padding: 0;
}

.title {
  font-size: 16px;
  color: #333;
  font-weight: 500;
  display: flex;
  align-items: center;

  .icon {
    color: #52C41A;
    font-size: 22px;
    padding-right: 16px;
  }
}

.pickers {
  padding: 30px 24px 0px;
}
</style>