<template>
  <el-dialog
    title="提示"
    :visible.sync="dialogVisible"
    width="800px"
    :before-close="handleClose"
  >
    <table class="table">
      <thead>
        <tr>
          <th>服务</th>
          <th>报税体检</th>
          <th>发票体检</th>
          <th>综合体检</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in tableData" :key="index">
          <td v-for="(td, index2) in row" :key="index2">
            <template v-if="td == 'Y'"> <i class="iconfont icon">&#xe62a;</i></template>
            <template v-else>
              {{ td }}
            </template>
          </td>
        </tr>
      </tbody>
    </table>
    <span slot="footer" class="dialog-footer">
      <el-button class="button" type="primary" @click="handleBtnApplyClick"
        >申请使用</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      tableData: [
        ["发票自动读取", "Y", "", "Y"],
        ["行业风险比对", "", "Y", "Y"],
        ["行业大数据比对", "", "", "Y"],
        ["发票申报表比对", "", "", "Y"],
        ["基础财税指标纵向分析", "Y", "", "Y"],
        ["行业大数据指标横向比对", "", "", "Y"],
        ["隐瞒收入异常", "Y", "", "Y"],
        ["财税数据匹配异常", "Y", "", "Y"],
        ["税会差异未调整", "Y", "", "Y"],
        ["进销发票匹配", "", "Y", "Y"],
        ["虚受发票风险", "", "Y", "Y"],
        ["异常开票风险", "", "Y", "Y"],
        ["供应商税务风险传导", "", "Y", "Y"],
        ["客户经营风险预警", "", "Y", "Y"],
      ],
    };
  },
  methods: {
    handleBtnApplyClick() {
      this.dialogVisible = false;
      this.$emit("apply");
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="stylus" scoped>
.table {
  border: 1px solid #D9D9D9;
  border-collapse: separate;
  border-spacing: 0px;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;

  th {
    font-size: 18px;
    padding: 24px 0;
    border-bottom: 1px solid #D9D9D9;
    color: rgba(0, 0, 0, 0.8);
  }

  th:last-child {
    border-right: none;
  }

  tr {
    td, th {
      border-right: 1px solid #D9D9D9;
    }

    td {
      padding: 16px 24px;
      color: rgba(0, 0, 0, 0.6);
      .icon{
        width 100%;
        display block;
        text-align center;
        color #2F54EB;
        font-size 21px
      }
    }

    td:last-child {
      border-right: none;
    }
  }

  tr:nth-child(odd) {
    td {
      background: #FBFBFB;
    }
  }
}

.form {
  margin: auto;
  width: 300px;
}

>>> .el-dialog {
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  padding: 48px 71px;

  .el-dialog__header {
    .el-dialog__title {
      font-size: 32px;
      font-weight: bold;
      color: #030852;
    }

    line-height: 42px;
    text-align: center;
    padding: 0 20px 16px;
  }

  .el-dialog__body {
    padding: 0;
    margin-top: 0;
  }

  .el-dialog__footer {
    text-align: center;
    padding: 12px 0 0px;
  }
}

>>> .el-input__inner {
  border-radius: 16px;
  border: 1px solid #D9D9D9;
}

.button {
  margin-top: 32px;
  width: 228px;
  height: 58px;
  background: linear-gradient(90deg, #5C8DF7 0%, #2F54EB 100%);
  border-radius: 16px;
  font-size: 20px;
  font-weight: bold;
}
</style>
