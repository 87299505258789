<template>
  <el-dialog
    title="联系我们"
    :visible.sync="dialogVisible"
    width="625px"
    :close-on-click-modal="false"
  >
    <div>
      <el-form
        ref="form"
        :model="form"
        label-width="100px"
        class="form"
        :rules="rules"
      >
        <el-form-item label="姓名：" prop="name">
          <el-input v-model="form.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="联系方式：" prop="contact">
          <el-input v-model="form.contact" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <button
        class="btn btn-primary btn-rounded"
        type="primary"
        @click="handleDialogFormClicked"
      >
        确 定
      </button>
    </span>
  </el-dialog>
</template>

<script>
import { addContactMsg } from "@/api/api";
export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        name: "",
        contact: "",
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        contact: [
          { required: true, message: "请输入联系方式", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    handleDialogFormClicked() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          addContactMsg({
            name: this.form.name,
            phone: this.form.contact,
          }).then((res) => {
            if (res.code == 200) {
              this.$alert(res.message, "提示", {
                type: "success",
              });
              this.dialogVisible = false;
            } else {
              this.$alert(res.message, "提示", {
                type: "error",
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

  },
};
</script>

<style lang="stylus" scoped>
.form {
  margin: auto;
  width: 300px;
}

>>> .el-dialog {
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 16px;

  .el-dialog__header {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid #eee;
    padding: 16px 20px 16px;
  }

  .el-dialog__body {
    padding: 0;
    margin-top: 48px;
  }

  .el-dialog__footer {
    text-align: center;
    padding: 12px 0 48px;
  }
}

.btn-rounded {
  line-height: 1.9;
}

>>> .el-input__inner {
  border-radius: 16px;
  border: 1px solid #D9D9D9;
}
</style>
