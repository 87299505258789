<template>
  <div class="wrapper">
    <div v-for="grp in node.children" :key="grp.id">
      <div class="grp" :id="'node' + grp.id">
        <div class="grp-title" :class="{ displayNone: grp.titleName == '' }">
          <span class="titleIcon"></span>
          <span class="red">{{ grp.orderNumber + ' ' }}{{ grp.titleName }}</span>
        </div>
        <div v-for="node in grp.children" :key="node.id">
          <!--发票-->
          <DropToggle v-if="node.type == 1" :id="'node' + node.id">
            <template v-slot:title>
             {{ node.orderNumber+node.titleName }}

              <!-- <WarningBadge :id="node.riskLevel"></WarningBadge> -->
            </template>
            <template v-slot:body>
              <InvoiceIndex :node="node"></InvoiceIndex>
            </template>
          </DropToggle>
          <!--/发票-->
          <!--税务-->
          <DropToggle v-if="node.type == 2" :id="'node' + node.id">
            <template v-slot:title>
              {{ node.orderNumber+node.titleName }}


              <!-- <WarningBadge :id="node.riskLevel"></WarningBadge> -->
            </template>
            <template v-slot:body>
              <RiskProblemList :node="node"></RiskProblemList>
            </template>
          </DropToggle>
          <!--/税务-->
          <!--财务-->
          <DropToggle v-if="node.type == 4" :id="'node' + node.id">
            <template v-slot:title>
              {{ node.orderNumber+node.titleName }}


              <!-- <WarningBadge :id="node.riskLevel"></WarningBadge> -->
            </template>
            <template v-slot:body>
              <TaxIndex :node="node"></TaxIndex>
            </template>
          </DropToggle>
          <!--/财务-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RiskProblemList from "@/pages/Result/components/RiskProblemList";
import TaxIndex from "@/pages/ResultEditor/components/TaxIndex";
import InvoiceIndex from "@/pages/ResultEditor/components/InvoiceIndex/InvoiceIndex";
import DropToggle from "./DropToggle";
import WarningBadge from "./compents/WarningBadge";
export default {
  components: {
    DropToggle,
    InvoiceIndex,
    WarningBadge,
    RiskProblemList,
    TaxIndex,
  },
  props: {
    node: Object,
  },
};
</script>

<style lang="stylus" scoped>
.titleIcon {
  width: 4px;
  height: 16px;
  background: #2663F6;
  border-radius: 2px 2px 2px 2px;
  margin-right: 8px;
  display inline-block
}
.wrapper{
  background #fff;
}
.grp {
  margin-bottom: 0;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);

  .grp-title {
    font-size: 18px;
    padding: 16px 0px;
    font-weight: bold;
    color: #333;
    border-bottom: 1px solid rgb(217, 217, 217);
    border-radius: 8px 8px 0px 0px;
    background: #fff;
  }
}
</style>
