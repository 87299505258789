<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="480px"
      height="214px"
      :close-on-click-modal="false"
    >
      <div class="wrapper">
        <div class="icon el-icon-s-opportunity"></div>
        <div class="content">
          <div class="title">上传文件已删除</div>
          <div class="text">鉴于保护用户的隐私及数据安全，本平台承诺检测后不保留任何用户的原始数据，您刚才上传用于检测的数据在生成检测报告后已全部销毁，请用户放心使用！</div>
          <span slot="footer" class="dialog-footer">
            <el-button
              type="primary"
              @click="handleConfirmClick"
              :disabled="leftSec>0"
              class="btn-ok"
            >
              我知道了
              <span v-if="leftSec>0">({{leftSec}})</span>
            </el-button>
          </span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>


<script>
export default {
  data() {
    return {
      dialogVisible: false,
      leftSec: 5,
      timer: null
    };
  },
  mounted() {
    //如果经过流程来到检测页面 就弹出数据安全提示框
    if (this.$store.state.Result.isFromUpload) {
      this.dialogVisible = true;
      this.$store.commit("Result/setIsFromUpload", false);
      this.timer = setInterval(() => {
        this.leftSec--;
        if (this.leftSec <= 0) {
          clearInterval(this.timer);
        }
      }, 1000);
    }
  },
  methods: {
    handleConfirmClick() {
      this.dialogVisible = false;
    }
  }
};
</script>
<style lang="stylus" scoped>
.wrapper {
  display: flex;

  .icon {
    color: rgb(250,173,20);
    font-size: 32px;
  }

  .content {
    margin-left: 16px;

    .title {
      font-weight: 600;
      color: #333;
      font-size: 16px;
      line-height: 24px;
    }

    .text {
      margin-top: 8px;
      font-size: 14px;
      color: #666;
      line-height: 1.5;
    }
  }
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;

  .btn-ok {
    flex-grow: 0;
    align-self: flex-start;
  }
}

>>> .el-dialog__header {
  padding: 0;
}
</style>
